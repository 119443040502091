.main-container {
    margin-top: -88px;
    margin-left: -8px;
    background: linear-gradient(to top, #FFE374, #474747);
    height: 100vh;
    width: 100%;
    position: absolute;
    overflow: clip;
}
.reset-password-main-container {
    margin-top: -88px;
    margin-left: -8px;
    background: white;
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.square-div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.reset-password-square-div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.reset-password-gradient {
    position: absolute;
    width: 100%;
    height: 200%;
    background-color: #FFFFFF;
    border-radius: 45px;
    left: 0;
    z-index: 10000;
    transition: all 1s ease-in-out;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 45px;
    box-shadow: 0 1px 28px 20px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 95%;
    height: 400px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    z-index: 1000;
}

.login-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: -8px;
    color: #323232;
}

.login-input {
    height: 35px;
    width: 60vw;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: -10px;
    margin-top: 10px;
    padding-left: 10px;
    font-size: 20px;
    color: #323232;
}

.login-paragraph {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 16px;
    color: #323232;
    width: 95%;
    text-align: center;
}
.login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    color: white;
    background-color: #FFE374;
    margin: 0 auto;
    margin-top: -20px;
    border-radius: 12px;
    font-size: 20px;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
    transition: 0.25s;
    cursor: pointer;
    border: transparent;
}

.login-button:hover {
    background-color: white;
    color: #FFE374;
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
    transition: 0.25s;
}

.login-error-message {
    color: red;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: -15px;
}

.login-bottom-links {
    display: flex;
    margin-top: 20px;
    font-size: 16px;
    color: #323232;
}

.background-square {
    position: absolute;
    background-color: #D9D9D9;
    border-radius: 45px;
    opacity: 0.2;
    box-shadow: 0 0px 36px 18px rgba(0, 0, 0, 1.45);
    z-index: 0;
}
.coverer {
    position: absolute;
    width: 100%;
    height: 2000%;
    background: linear-gradient(to top, #FFFFFF, #00000000);
    top: newY;
    left: 0;
    z-index: 10000;
    transition: all 1s ease-in-out;
}

.contact-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 28px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    z-index: 999;
}

.contact-us-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #323232;
}

.contact-us-input {
    width: 100%;
    max-width: 300px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 16px;
}

.sign-up-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 45px;
    box-shadow: 0 1px 28px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    height: 500px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.sign-up-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -8px;
    color: #323232;
}

.sign-up-input {
    width: 100%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 20px;
    color: #323232;
}

.sign-up-paragraph {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    font-size: 20px;
    color: #323232;
    width: 70%;
    text-align: center;
}

.sign-up-confirm {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 12px;
    color: #323232;
    width: 95%;
    text-align: center;
}

.sign-up-button {
    display: block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 175px;
    height: 50px;
    border-radius: 20px;
    background-color: #FFE374;
    font-size: 32px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    border: transparent;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
}

.sign-up-already {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    color: #323232;
}

.sign-up-parent-container {
    background: linear-gradient(to top, #FFE374, #474747);
    height: 100vh;
}

.sign-up-square-div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}