.profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 10%;
    margin-top: 5%;
    box-shadow: var(--list-item-shadow);
}

h2{
    font-weight: bolder;
    color: var(--text);
}

form{
    width:650px;
}

form *{
    font-weight: bold;
}

form label{
    font-size: 15px;
    color: var(--text);
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
    border-radius: 20px;
    box-shadow: var(--list-item-shadow);
    padding-bottom: 40px;
}

form .form-control,form .form-control:focus{
    border-color:transparent;
    border-bottom-color: var(--light-grey);
    box-shadow:none;
}

form .btn{ 
    border-radius: 0px;
    border-color: transparent;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
}

.button-save {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: -20px;
    width: 150px;
    height: 35px;
    border-radius: 12px;
    background-color: var(--yellow);
    font-size: 20px;
    font-weight: bold;
    color: var(--grey);
    cursor: pointer;
    border: transparent;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
}

.button-save:hover {
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.2);
}

.button-logout {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: -20px;
    width: 150px;
    height: 35px;
    border-radius: 12px;
    background-color: var(--light-grey);
    font-size: 20px;
    font-weight: bold;
    color: var(--grey);
    cursor: pointer;
    border: transparent;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
}

.button-logout:hover {
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.2);
    background-color: #f7c5c5;
    transition: 0.2s;
}

.input {
    display: inline-block;
    padding: 5px;
    font-size: 12px;
    color: black;
    width: auto;
    height: 30px;
    min-width: 50px;
    max-width: 600px;
    font-weight:500;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.2s ease;
    background-color: white;
    color: var(--grey);
    margin-top: 15px;
}

.input::placeholder {
    color: rgba(170, 170, 170, 0.8);
}

.input:focus::placeholder {
    color: transparent;
}

.input:focus {
    outline: none;
    border-color: var(--yellow);
}