.reload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background);
    color: var(--icon-invert);
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    font-size: 20px;
    box-shadow: var(--list-item-shadow);
}

.reload-button:hover {
    background: var(--background);
}

.reload-button:active {
    box-shadow: inset var(--list-item-shadow);
}

.lot-sorter {
    color: var(--text)
}

.lot-loader-container {
    position: fixed;
    display: flex;
    width: 110%;
    height: 110vh;
    margin-left: -30px;
    margin-top: -100px;
    background: var(--header-color);
    box-shadow: inset 0 0 2000px var(--header-color);
    backdrop-filter: blur(10px);
    justify-content: space-between;
    align-items: center;
    color: var(--background);
    z-index: 999;
}

.lot-loader-div {
    width: 150px;
    height: 150px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lot-item {
    height: auto;
    color: black;
    display: flex;
    justify-content: center;
    text-align: left;
}

.lot-rounded-rectangle {
    white-space: nowrap;
    width: 95%;
    min-width: 320px;
    height: 45px;
    border-radius: 20px;
    background: linear-gradient(to right, var(--light-grey), var(--white));
    padding: 14px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    color: var(--text);
    align-content: center;
    align-items: center;
    box-shadow: var(--list-item-shadow);
}

/* linear-gradient(to right, var(--li-gradient), var(--lot-background)); */

.lot-item-content {
    display: flex;
    flex-direction: row;
}

.lot-left {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    border-radius: 10px;
    width: 45vw;
}

.lot-left-top {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

.lot-item-num {
    font-weight: 900;
}

.lot-item-name {
    margin-left: 5px;
    margin-top: 4px;
    font-size: 14px;
    font-weight: medium;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.lot-item-count {
    font-size: 14px;
    margin-top: 5px;
}

.lot-item-spots-left {
    font-size: 18px;
    margin-right: 15px;
    display: flex;
    width: 20px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lot-center {
    display: flex;
    margin-left: auto;
    margin-right: -70px;
    margin-bottom: 10px;
    font-size: 18px;
}

.lot-right {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    border-radius: 10px;
    width: 150px;
}

.lot-item-icon {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 10px;
    color: var(--icon-invert);
}

:root {
    --arrow-bg: #949494;
    --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
    --option-bg: #323232;
    --select-bg: rgba(255, 255, 255, 0.2);
}

.loader-parent-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: -10px;
    width: 98%;
    background-color: var(--background);
    backdrop-filter: blur(10px);
    justify-content: space-between;
    align-items: center;
    color: var(--background);
    z-index: 999;
}

.loader-item {
    display: flex;
    justify-content: center;
    width: 95%;
    box-shadow: var(--list-item-shadow);
    white-space: nowrap;
    margin-bottom: 20px;
    min-width: 320px;
    height: 75px;
    border-radius: 20px;
    align-items: center;
}

.loader-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: auto;
    margin-left: 20px;
}

.loader-right {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.loader-bar {
    display: flex;
    margin-right: 10px;
}

.barchart-container {
    display: flex;
    flex-direction: row;
    width: 25vw;
    max-width: 150px;
    height: 12px;
    background-color: var(--light-yellow);
    border-radius: 50px;
    box-shadow: 0px .88px 2.38px 0.88px rgba(0, 0, 0, .15);
    margin-right: 30px;
}

.barchart-fill {
    height: 100%;
    border-radius: 50px;
}

.caret-container {
    display: flex;
    margin-left: auto;
}

.lot-item-arrow {
    display: flex;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 10px;
    color: var(--medium-grey);
}

.barchart-overlay-container {
    position: absolute;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.barchart-overlay {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .1);
    position: absolute;
    top: 0px;
    left: -100px;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 180px;
    height: auto;
    font-size: 16px;
}

.top-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
}

/* Filter Button */
.sort-button {
    color: #323232;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 8px 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.sort-button:hover {
    background-color: #f7f7f7;
}

.sort-button-icon {
    font-size: 20px;
    margin-right: 5px;
}

.sort-dropdown {
    position: absolute;
    top: 40px;
    right: 20px;
    background: var(--background);
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.sort-dropdown select {
    background: var(--background);
    border: none;
    font-size: 15px;
    padding: 8px;
    border-radius: 4px;
    outline: none;
    appearance: none;
    cursor: pointer;
}

@keyframes fadeLeftToRight {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}